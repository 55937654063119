import { BsCalendar2Date } from "react-icons/bs";
import Aos from "aos";
import "aos/dist/aos.css";
import Resume from "../../assets/resume/resume_Tamas.pdf";
import "./about.scss";

const About = ({ menu }) => {
  Aos.init({ duration: 1000 });
  return (
    <section className={`about ${menu && "open"}`}>
      <div className="container">
        <div className="row">
          <div className="section-title padd-15">
            <h2>About Me</h2>
          </div>
        </div>
        <div className="row">
          <div className="about-content padd-15">
            <div className="row">
              <div className="about-text">
                <h3 data-aos="fade-in">
                  I'm Tamas Jin, a <span>Full-Stack / Web Developer</span>
                </h3>
                <p data-aos="fade-in">
                  I'm a complex problem-solver with an analytical and driven
                  mindset and yet an organized and cooperative team worker
                  dedicated to achieving demanding development objectives
                  according to tight schedules while producing impeccable code.
                  I love spending my time doing code reviews, testing, or
                  sometimes just helping my peers out during Scrum meetings and
                  leading junior developers in the team.
                </p>
              </div>
            </div>
            <div className="row" data-aos="fade-in">
              <div className="personal-info padd-15">
                <div className="row">
                  <div className="info-item padd-15" data-aos="fade-in">
                    <p>
                      Alias : <span>tamas</span>
                    </p>
                  </div>
                  <div className="info-item padd-15" data-aos="fade-in">
                    <p>
                      Website : <span>https://github.com/SatalBill</span>
                    </p>
                  </div>
                  <div className="info-item padd-15" data-aos="fade-in">
                    <p>
                      Email : <span>sanjose.reactdeveloper@gmail.com</span>
                    </p>
                  </div>
                  <div className="info-item padd-15" data-aos="fade-in">
                    <p>
                      Degree : <span>Bachelor in Computer Science</span>
                    </p>
                  </div>
                  <div className="info-item padd-15" data-aos="fade-in">
                    <p>
                      Certifications : <span>Full Stack Developer</span>
                    </p>
                  </div>
                  <div className="info-item padd-15" data-aos="fade-in">
                    <p>
                      Phone : <span>+1 (213) 592 8443</span>
                    </p>
                  </div>
                </div>
                <div className="row" data-aos="fade-in">
                  <div className="buttons">
                    <a
                      href={Resume}
                      download
                      className="btn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Get Resume
                    </a>
                  </div>
                </div>
              </div>
              <div className="skills padd-15">
                <div className="row">
                  <div className="skill-item" data-aos="fade-in">
                    <h3>ReactJS / NextJS</h3>
                    <div className="progress">
                      <div className="progress-in" style={{ width: "90%" }} />
                      <div className="skill-percent">90%</div>
                    </div>
                  </div>
                  <div className="skill-item" data-aos="fade-in">
                    <h3>NodeJS / ExpressJS</h3>
                    <div className="progress">
                      <div className="progress-in" style={{ width: "80%" }} />
                      <div className="skill-percent">80%</div>
                    </div>
                  </div>
                  <div className="skill-item" data-aos="fade-in">
                    <h3>Web3.js / Ethers.js</h3>
                    <div className="progress">
                      <div className="progress-in" style={{ width: "75%" }} />
                      <div className="skill-percent">75%</div>
                    </div>
                  </div>
                  <div className="skill-item" data-aos="fade-in">
                    <h3>Tailwind / MaterialUI</h3>
                    <div className="progress">
                      <div className="progress-in" style={{ width: "95%" }} />
                      <div className="skill-percent">95%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="education padd-15" data-aos="fade-in">
                <h3 className="title">Experience</h3>
                <div className="row">
                  <div className="timeline-box">
                    <div className="timeline shadow-dark">
                      <div className="timeline-item">
                        <div className="circle-dot" />
                        <h3 className="timeline-date">
                          <BsCalendar2Date className="fa fa-calendar" />
                          <i>2015.02-2017.08</i>
                        </h3>
                        <h4 className="timeline-title">
                          MERN stack Developer @ BMC Software, United States
                        </h4>
                        <p className="timeline-text">
                          - Analyzed project requirements, translating
                          specifications into functional application components.
                        </p>
                        <p className="timeline-text">
                          - Coded, tested, debugged, documented and implemented
                          web applications.
                        </p>
                      </div>
                      <div className="timeline-item">
                        <div className="circle-dot" />
                        <h3 className="timeline-date">
                          <BsCalendar2Date className="fa fa-calendar" />
                          <i>2017.09-2019.11</i>
                        </h3>
                        <h4 className="timeline-title">
                          Full Stack Web Developer @ InterDigital, United States
                        </h4>
                        <p className="timeline-text">
                          - Worked within Agile frameworks to consistently meet
                          project timelines and deliverable objectives.
                        </p>
                        <p className="timeline-text">
                          - Contributed to code reviews, debugging and
                          optimization.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="experience padd-15" data-aos="fade-in">
                <h3 className="title">Experience</h3>
                <div className="row">
                  <div className="timeline-box">
                    <div className="timeline shadow-dark">
                      <div className="timeline-item">
                        <div className="circle-dot" />
                        <h3 className="timeline-date">
                          <BsCalendar2Date className="fa fa-calendar" />
                          <i>2019.12 - 2021.06</i>
                        </h3>
                        <h4 className="timeline-title">
                          Lead Frontend & Web3 Developer @Bizness Apps, United States
                        </h4>
                        <p className="timeline-text">
                          - Improved coding of JavaScript and CSS to enhance
                          user experience and functionality.
                        </p>
                        <p className="timeline-text">
                          - Continuously studied and improved abilities with
                          wide range of web technologies, design tools and
                          programming practices.
                        </p>
                      </div>
                      <div className="timeline-item">
                        <div className="circle-dot" />
                        <h3 className="timeline-date">
                          <BsCalendar2Date className="fa fa-calendar" />
                          <i>2021.06 - 2022.07</i>
                        </h3>
                        <h4 className="timeline-title">Full Stack Developer @Self Employed, United States</h4>
                        <p className="timeline-text">
                          - Produced high-quality documents, spreadsheets, and
                          presentations for internal and customer-facing needs,
                          improving customer satisfaction and speeding up
                          internal processes by a factor of 1.5.
                        </p>
                        <p className="timeline-text">
                          - Performed fault analysis in substation generators
                          and came up with preventive solutions that reduced
                          maintenance downtimes by 120%.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
